// Import colors
@import '../componets/_base-style/colors.scss';
// Import fonts
@import '../componets/_base-style/fonts.scss';



.aboutpage {


    .intro {
        padding: 17vh 0 0;
        margin-bottom: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        h1 {
            position: absolute;
            width: 100%;
            z-index: 1;
            text-align: center;

            bottom: 5%;
            margin: 0;
        }

        &__portrait {
            display: block;
            position: relative;

            width: 40vw;
            height: 50vw;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }


    .personal-skills {

        p {
            text-align: center;
            display: flex;
            margin: 0;
            justify-content: center;
            font-size: 32px;
            line-height: 32px;

            strong {
                padding: 9px 19px 17px;
                border: 1px solid #fff;
                border-radius: 100px;
                margin: 4px;
                user-select: none;

                transition: .25s;
                transition-timing-function: ease-in-out;
                opacity: 0;
                transform: translateY(-100%);
            }
        }


        // delay on buttons
        $about-button-delay-gainer : 40;
        $about-button-1-delay : 0 * $about-button-delay-gainer;
        $about-button-2-delay : 2 * $about-button-delay-gainer;
        $about-button-3-delay : 3 * $about-button-delay-gainer;
        $about-button-4-delay : 4 * $about-button-delay-gainer;
        $about-button-5-delay : 5 * $about-button-delay-gainer;
        $about-button-6-delay : 6 * $about-button-delay-gainer;
        $about-button-7-delay : 7 * $about-button-delay-gainer;
        $about-button-8-delay : 8 * $about-button-delay-gainer;
        $about-button-9-delay : 9 * $about-button-delay-gainer;
        $about-button-10-delay : 10 * $about-button-delay-gainer;
        $about-button-11-delay : 11 * $about-button-delay-gainer;
        $about-button-12-delay : 12 * $about-button-delay-gainer;


        // rack 1
        p:nth-child(1) {
            strong:nth-child(1) {
                transition-delay: $about-button-1-delay+ms;
            }

            strong:nth-child(2) {
                transition-delay: $about-button-2-delay+ms;
            }

            strong:nth-child(3) {
                transition-delay: $about-button-3-delay+ms;
            }

        }

        // rack 2
        p:nth-child(2) {
            strong:nth-child(1) {
                transition-delay: $about-button-4-delay+ms;
            }

            strong:nth-child(2) {
                transition-delay: $about-button-5-delay+ms;
            }

            strong:nth-child(3) {
                transition-delay: $about-button-6-delay+ms;
            }

            strong:nth-child(4) {
                transition-delay: $about-button-7-delay+ms;
            }

            strong:nth-child(5) {
                transition-delay: $about-button-8-delay+ms;
            }
        }

        // rack 3
        p:nth-child(3) {
            strong:nth-child(1) {
                transition-delay: $about-button-9-delay+ms;
            }

            strong:nth-child(2) {
                transition-delay: $about-button-10-delay+ms;
            }

            strong:nth-child(3) {
                transition-delay: $about-button-11-delay+ms;
            }

            strong:nth-child(4) {
                transition-delay: $about-button-12-delay+ms;
            }
        }



        &.active {
            p strong {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }

    .personal-description {
        padding: 20vh 0;
    }

}

@media only screen and (max-width: 600px) {

    .aboutpage {

        .intro {
            padding: 20vh 0 0;
            margin-bottom: 80px;

            h1.header__100px {
                font-size: 30px;
            }

            &__portrait {
                width: 75vw;
                height: 85vw;
            }
        }

        .personal-skills {

            p {
                flex-direction: column;
                text-align: left;
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 12px;

                strong {
                    padding: 6px 12px 6px;
                }
            }
        }

        .personal-description{
            padding: 80px 0 0;

            p.header__58px{
                font-size: 18px;
            }
        }

    }

}