// Import colors
@import '../componets/_base-style/colors.scss';
// Import fonts
@import '../componets/_base-style/fonts.scss';

.contact-page {

    min-height: 100vh;
    padding: 340px 80px 160px;


    &__form {
        border: 1px solid $color--white-20;

        &--send {
            animation: send-formular 3.5s;
        }

        @keyframes send-formular {
            20% {
                transform: scale(0.8);
            }

            40% {
                transform: translateX(120vw) scale(0.8);
                opacity: 1;
            }

            42% {
                transform: translateX(120vw) scale(0.8);
                opacity: 0;
            }

            43% {
                transform: translateX(-120vw) scale(0.8);
                opacity: 0;
            }

            44% {
                transform: translateX(-120vw) scale(0.8);
                opacity: 1;
            }

            80% {
                transform: translateX(0) scale(0.8);
                opacity: 1;
            }

            100% {
                transform: scale(1);
                opacity: 1;
            }

        }

        & textarea {
            display: block;
            background: none;
            outline: none;
            border: none;
            width: 95%;
            margin: 32px auto;
            font-size: 80px;
            line-height: 1.1;
            color: $color--white-20;

            & {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & input {
            display: block;
        }

        &-note{
            font-size: 14px;
            font-weight: 600;
            opacity: 0.5;
        }
    }

    &__cta {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 80px;
        margin-bottom: 120px;

        h1 {
            grid-column: 1 / 13;
            margin-top: unset;
        }

        p {
            grid-column: 1 / 7;
            margin: unset;
        }

        .buttons {
            grid-column: 1 / 13;
            margin-top: 32px;

            a {
                display: inline-block;
                margin: 4px;
                padding: 9px 19px 17px;
                border: 1px solid #fff;
                font-size: 24px;
                line-height: 24px;
                font-weight: 700;
                border-radius: 100px;
                text-decoration: none;
                justify-content: center;
            }
        }
    }


    &__button {
        display: block;
        background: none;
        outline: none;
        border: none;
        margin: 15px;
        color: $color--white-20;
        font-size: 28px;
        line-height: 1;
        cursor: pointer;


        &-cnt {
            display: flex;
            justify-content: center;
        }

        &-info-cnt {
            display: flex;

            & input {
                background: none;
                color: $color--white-20;
                font-size: 28px;
                line-height: 1;
                padding: 15px 32px;

                outline: none;
                border: 1px solid $color--white-20;
                border-bottom: none;
                border-left: none;
                transition: all .3s;
            }

            & input::placeholder {
                color: $color--white-20;
                font-size: 28px;
                line-height: 1;
                transition: all .3s;
            }
        }
    }
}

.form-input-incorrect {
    border-color: $color--red !important;
    color: $color--red !important;

    &::placeholder {
        color: $color--red !important;
    }
}


@media only screen and (max-width: 600px) {

    .contact-page {
        min-height: unset;
        padding: 22vh 20px 64px;

        &__button {
            font-size: 20px;
            margin: unset;
            padding: 15px 12px;

            &:last-child {
                margin-left: auto;
            }


            &-cnt {
                justify-content: flex-start;
            }

        }

        &__form {
            display: none;

            & textarea {
                font-size: 15px;
            }
        }

        &__cta {
            display: block;
            margin-bottom: unset;

            h1{
                font-size: 44px;
            }


            .buttons {
                margin-top: 32px;
                display: flex;

                a {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
        }

    }

}