// Import colors
@import '../componets/_base-style/colors.scss';


.theme {

    .play-quiz {

        &__points {
            background: $color--black-20;
            transition: .6s;
        }

        &__modal {
            background: $color--white-20;
            color: $color--black-30;
        }
    }


    &--blue {
        .play-quiz {

            &__points {
                background: $color--blue;
            }

            &__modal {
                background: $color--blue;
                color: $color--white-20;

                &-close {
                    background: rgba(255, 255, 255, 0.1);

                    &:hover {
                        background: rgba(255, 255, 255, 0.2);
                    }
                }

                &-btn {
                    color: $color--white-20;
                    background: $color--blue;
                    border-color: $color--white-20;
                }
            }

        }
    }

}

.play-quiz {

    &__points {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        // background: $color--blue-light;
        // background: $color--yellow;
        opacity: 0;
        z-index: 125;
        user-select: none;
        pointer-events: none;
        transition: 1.5s;
        transition-delay: 1s;

        &--active {
            opacity: 1;
            user-select: auto;
            pointer-events: all;
            transition-delay: unset;

            &-closemodal {
                opacity: 1;
                user-select: auto;
                pointer-events: all;
                transition-delay: unset;
            }
        }

        &-cnt {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        &-button {
            padding: 0 16px;
            background: unset;
            outline: unset;
            border: unset;
        }

        &-icon {
            transition: .2s;

            &:hover {
                opacity: 0.8;
            }

            &--active {
                animation: pointActive 1s .7s cubic-bezier(0.64, 0.57, 0.67, 1.53);
            }

            @keyframes pointActive {
                0% {
                    transform: scale(1);
                }

                30% {
                    transform: scale(0);
                }

                60% {
                    transform: scale(0);
                }

                100% {
                    transform: scale(1)
                }
            }
        }

        &-circel {
            display: none;
            pointer-events: none;
            cursor: none;

            position: fixed;
            z-index: 120;
            left: -20vw;
            bottom: -20vh;
            padding: 10px;

            background: unset;
            outline: unset;
            border: unset;

            &--active {
                display: initial;
                pointer-events: initial;
                cursor: pointer;
                animation: fly 75s 5s linear infinite;
            }


            &-icon {
                transform-origin: center center;
                animation: rotate 6.5s linear infinite;
                width: 6vw;
            }

            &-scale {
                display: inline-block;
                transition: .2s;
            }

            &:hover &-scale {
                transform: scale(1.25);
            }
        }

        @keyframes fly {
            0% {
                transform: translate(-30vw, 0vh);
            }

            20% {
                transform: translate(120vw, -120vh);
            }

            30% {
                transform: translate(-20vw, -110vh);
            }

            // fly
            50% {
                transform: translate(120vw, -40vh);
            }

            60% {
                transform: translate(70vw, 20vh);
            }

            // fly
            80% {
                transform: translate(-20vw, -60vh);
            }

            95% {
                transform: translate(-20vw, -60vh);
            }

        }


        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg)
            }
        }
    }

    &__modal {
        position: fixed;
        // background: $color--yellow;
        // color: $color--black-20;
        top: 50%;
        left: 50%;
        width: 95vh;
        max-width: 940px;
        height: 95vh;
        max-height: 940px;
        border-radius: 95vw;
        z-index: 230;
        padding: 126px 40px;

        transform: translate(-50%, -50%) scale(0);
        user-select: none;
        pointer-events: none;
        overflow: hidden;
        transition-timing-function: ease-in-out;
        transition-duration: .6s;

        &--active {
            transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
            transform: translate(-50%, -50%) scale(1);
            user-select: auto;
            pointer-events: auto;
            overflow: hidden;

            &-project {
                width: 95vh;
                max-width: 940px;
                height: 80vh;
                max-height: unset;
                padding: 54px 40px;
                border-radius: 30px;
            }
        }

        & h2,
        & h4,
        & p {
            max-width: 460px;
            margin: 0 auto 12px;
            text-align: center;
        }

        & h2 {
            margin-bottom: 132px;
        }

        &-title--project {
            margin-bottom: 32px !important;
        }

        &-sub--inactive,
        &-body--inactive {
            display: none;
        }

        &-btn {
            margin: 0 10px;
            padding: 17px 42px 19px;

            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: $color--black-30;
            background: $color--white-20;
            border-style: solid;
            border-color: $color--black-30;
            border-width: 2px;

            position: relative;
            outline: none;
            border-radius: 56px;
            cursor: pointer;
            overflow: hidden;

            & span {
                position: relative;
                z-index: 2;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -21vw;
                left: 50%;
                width: 20vw;
                height: 20vw;
                background: $color--danger-red;
                transform: translate(-50%, 0);
                border-radius: 20vw;
                transition: .4s ease;
            }

            &:focus::after {
                transform: translate(-50%, -90px);
            }

            &-correct {
                &::after {
                    background: $color--green;
                }
            }


            &-cnt {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 48px;
            }
        }

        &-close {
            position: absolute;
            left: 50%;
            bottom: 42px;
            transform: translateX(-50%);
            cursor: pointer;
            padding: 12px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: rgba(0, 0, 0, 0.1);
            transition: .4s;

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }

            & img {
                transition: .4s;
            }

            &:hover img {
                transform: scale(0.8);
            }

            &-project {
                left: unset;
                bottom: unset;
                transform: unset;
                right: 20px;
                top: 20px;
            }
        }

        &-iframe {
            display: none;

            &--active {
                display: block;
                width: 200%;
                height: 150%;
                transform: scale(0.5);
                transform-origin: 0 0;
                background-color: white;

                overflow: hidden;
                border: none;
            }
        }

    }


    &--active::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 200;
        background: rgba($color: #000000, $alpha: .4);
    }

}

@media only screen and (max-width: 600px) {

    .play-quiz {
        &__points-circel-icon {
            width: 20vw;
        }

        &__modal {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(100vw - 20px);
            height: 70vh;
            border-radius: 25px;
            padding: 47px 34px;

            &--active {
                border-radius: 25px;
            }

            & h2 {
                margin-bottom: 24px;
            }

            & h4 {
                font-size: 20px;
                line-height: 24px;
                margin: 0 0 8px;
            }

            &-btn {

                margin: 0 4px;
                padding: 15px 32px 17px;

                &::after{
                    width: 35vw;
                    height: 35vw;
                    bottom: -35vw;
                }

                &:focus::after,
                &:hover::after,
                &:active::after{
                    transform: translate(-50%, -20vw);
                }

                &-cnt {
                    margin-top: 20px;
                }
            }

            &-close {
                position: initial;
                transform: initial;
                margin-top: 32px;
                order: 100;
            }
        }

    }

}