// Whites
$color--white: #fff;
$color--white-20: #F4EFE2;

// Black
$color--black-10: #727272;
$color--black-20: #343535;
$color--black-30: #222222;

// Blues
$color--blue: #3860FF;
$color--blue-light: #69CFD9;
$color--blue-light2: #96DDE4;

// greens
$color--green: #69D979;

// Reds
$color--red: #D34E45;
$color--danger-red: #DA0018;

// Yellows
$color--yellow: #FFD538;
// $color--yellow: #efc734;

// Oranges
$color--orange: #FF6A34;