// Import colors
@import '../componets/_base-style/colors.scss';
// Import fonts
@import '../componets/_base-style/fonts.scss';


.not-found-page{

    &__go-back{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;

        & h1 {
            margin: 0;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            text-align: center;
        }

        & a{
            margin-top: 32px;
            text-decoration: none;
            
            padding: 17px 42px 19px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #fff;
            background: $color--blue;
            // background: $color--yellow;
            border-radius: 56px;
            transform: translateY(0);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
            transition: all .2s;
            
            &:hover {
                transform: translateY(-3px);
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            }
        }
    }

    &__404{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 60vw;
        line-height: 1;
        margin: 0;
        color: rgba($color: #fff, $alpha: 0.1);
        user-select: none;
    }

    &__roling-eyes{

        &-block{
            position: relative;
            height: 100vh;
            overflow: hidden;
        }

        &-cnt{
            position: absolute;
            top: 50vh;
            left: 50%;
            width: 180px;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: space-around
        }

        &-img{
            height: 100%;
            width: 750px;
            object-fit: cover;
            object-position: 50% 50%;

            &-cnt{
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
            }
        }

    }

    &__roling-eye{

        width: 40px;
        height: 40px;
        background: #724915;
        border-radius: 3vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        &--inner{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background: #000;
            width: 20px;
            height: 20px;
            border-radius: 1.2vw;
            transform: translate(-50%, -50%);
        }
        
        
        &-outer{
            position: relative;
            width: 60px;
            height: 60px;
            background: #fff;
            border-radius: 5vw;
            overflow: hidden;
        }

        // &--1{}
        // &--2{}

    }
}

// .theme--black .not-found-page__go-back a{
//     background: $color--black-20;
// }