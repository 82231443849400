.reading-animation {

    span {
        opacity: 0.2;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
    }

    &.active span{
        opacity: 1;
    }

    @for $i from 0 to 200 {
        span:nth-child(#{$i + 1}) {
            transition-delay: $i * 0.1s;
        }
    }
}