.titles {
    font-family: 'Yeseva One', serif;
}

.body {
    font-family: 'Open Sans', sans-serif;
}


.header {
    &__24px {
        font-size: 24px;
        line-height: 32px;
    }

    &__58px {
        font-size: 58px;
        line-height: 1;
    }

    &__70px {
        font-size: 70px;
        line-height: 72px;
    }

    &__100px {
        font-size: 100px;
        line-height: 1;
        letter-spacing: 1px;
    }
}

.body {
    &__18px {
        font-size: 18px;
        line-height: 24px;
    }

}


@media only screen and (max-width: 600px) {
    .header {
        &__24px {
            font-size: 24px;
            line-height: 32px;
        }

        &__58px {
            font-size: 28px;
            line-height: 1;
        }

        &__70px {
            font-size: 40px;
            line-height: 42px;
        }

        &__100px {
            font-size: 48px;
            line-height: 1;
            letter-spacing: 1px;
        }
    }
}