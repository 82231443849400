// Import colors
@import '../componets/_base-style/colors.scss';

.work-page {

    &.wrapper {
        padding: 340px 80px 160px;
    }

    .intro.grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 80px;
        margin-bottom: 120px;

        h1 {
            grid-column: 1/13;
        }

        p {
            grid-column: 1/7;
            margin: 0;
        }
    }


    &__show {
        margin-top: 40px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 25px;
        padding-bottom: 100px;

        & .work-tiel {
            grid-column: auto / span 6;
            transform: unset;
            margin-top: 20px;
        }
    }

    &__content {
        min-width: 100%;
        margin-top: 32px;

        &-owners {
            grid-column: auto / span 12;
            font-size: 14px;
        }
    }

    &__next-project {
        position: sticky;
        top: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-decoration: none;
        height: 21px;
        font-size: 21px;
        line-height: 1;
        font-weight: 700;
        white-space: nowrap;
        z-index: 110;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);

        &-large {
            grid-column: 1 / span 6;
            margin-top: 20px;

            h4,
            h5 {
                margin: unset;
            }

            h5 {
                margin-bottom: 16px;
            }
        }

        &-line {
            width: 42px;
            height: 2px;
            background: #fff;
            margin-right: 22px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.33);
        }
    }

    &__video-cnt {
        grid-column: 1 / span 12;
        // height: 85vh;

        &--small {
            grid-column: 2 / span 10;

            & img,
            & video {
                width: 100%;
            }
        }

        & img,
        & video {
            width: 100%;
        }
    }

    &__mobile {
        grid-column: auto / span 6;
        padding: 82px 20px;
        background: $color--blue-light2;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            max-width: 280px;
        }
    }

    &__image-full {
        grid-column: 1 / span 12;

        &-page {
            grid-column: 2 / span 10;
            // margin-top: 138px !important;
            margin-top: 138px;

            &:nth-of-type(2n+1) {
                // margin-top: calc(-25px - 60px) !important;
                margin-top: calc(-25px - 60px);
            }

            &--no-margin:nth-of-type(1n) {
                // margin-top: 138px !important;
                margin-top: 138px;
            }


        }
    }


    &__small-overlay {
        grid-column: auto / span 10;
        // calc grid gap minus space for overlay
        margin-top: calc(-25px - 60px);

        &:first-of-type {
            margin-top: unset;
        }

        &--right {
            grid-column: 2 / span 12;
        }
    }
}

@media only screen and (max-width: 600px) {

    .work-page {


        &.wrapper {
            padding: 20vh 20px 0;
        }

        .intro.grid {
            grid-gap: 0 0;
            margin-bottom: 80px;

            p {
                grid-column: 1/13;
            }
        }


        &__grid {
            padding-bottom: initial;
            grid-gap: 14px;

            & .work-page__next-project-large {
                margin-top: initial;
                grid-column: auto/span 12;

                h5 {
                    margin-bottom: 2px;
                }
            }

            & .work-tiel {
                margin-top: initial;
                grid-column: auto/span 12;
            }
        }

        &__mobile {
            grid-column: auto/span 12;
        }

        &__image-full-page {

            grid-column: auto/span 12;

            &--no-margin {

                &:nth-of-type(1n) {
                    // margin-top: unset !important;
                    margin-top: initial;
                }
            }

            &:nth-of-type(2n+1) {
                margin-top: initial;
            }
        }

        &__small-overlay {
            grid-column: auto/span 12;
            margin-top: initial;
        }

        &__video-cnt {
            &--small {
                grid-column: auto/span 12;
            }
        }

    }
}