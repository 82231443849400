.work-tiel {
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    grid-column: 1 / 7;
    transform: translateY(180px);

    &:nth-child(even) {
        transform: translateY(0);
        grid-column: 7 / 13;
    }

    &__dec {
        margin-right: 22px;
        transform: rotate(180deg);
        writing-mode: vertical-lr;
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        filter: grayscale(0);
        transition: .4s;

        &-cnt {
            overflow: hidden;
            position: relative;
            width: 100%;
            min-height: 840px;
            max-height: 80vh;

            border-radius: 0;
            transition: 0.3s;
            box-shadow: 6px 12px 18px rgba(51, 51, 51, 0);


            &:hover img {
                filter: grayscale(0.3) blur(4px);
                transform: scale(1.08);
            }

            &:hover {
                // animation: boobling 100s infinite;
                // box-shadow: 6px 12px 18px rgba(51, 51, 51, 0.4);
            }

            &:hover .work-tiel__img-mask {
                transform: scale(1.5);

                img {
                    opacity: 1;
                    filter: unset;
                    transform: scale(0.75);
                }
            }

            @keyframes boobling {

                // 0% {
                //     border-radius: 0;
                // }
                2% {
                    border-radius: 5% 14% 54% 3% / 22% 6% 14% 25%;
                }

                6% {
                    // border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                12% {
                    border-radius: 15% 21% 84% 18% / 32% 31% 14% 12%;
                }

                18% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                24% {
                    border-radius: 5% 14% 54% 3% / 22% 6% 14% 25%;
                }

                30% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                35% {
                    border-radius: 15% 21% 84% 18% / 32% 31% 14% 12%;
                }

                40% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                45% {
                    border-radius: 5% 14% 54% 3% / 22% 6% 14% 25%;
                }

                50% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                55% {
                    border-radius: 15% 21% 84% 18% / 32% 31% 14% 12%;
                }

                60% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                65% {
                    border-radius: 5% 14% 54% 3% / 22% 6% 14% 25%;
                }

                70% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                75% {
                    border-radius: 15% 21% 84% 18% / 32% 31% 14% 12%;
                }

                80% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                85% {
                    border-radius: 5% 14% 54% 3% / 22% 6% 14% 25%;
                }

                90% {
                    border-radius: 40% 10% 10% 40% / 30% 20% 30% 20%;
                }

                95% {
                    border-radius: 15% 21% 84% 18% / 32% 31% 14% 12%;
                }

                100% {
                    border-radius: 0;
                }
            }
        }

        &-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            transform-origin: 50% 50%;
            user-select: none;
            pointer-events: none;

            mask-image: url('../assets/img/shader.svg');
            -webkit-mask-image: url('../assets/img/shader.svg');
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;

            transform: scale(0);
            transition: transform .5s;

            & img {
                opacity: 0;
            }
        }
    }
}


@media only screen and (max-width: 600px) {

    .work-tiel {
        grid-column: 1/13;
        transform: initial;
        // display flex
        flex-direction: column-reverse;
        align-items: flex-start;

        &:nth-child(even) {
            transform: initial;
            grid-column: 1/13;
        }


        &__dec {
            font-size: 16px;
            margin-top: 10px;
            margin-right: initial;
            transform: initial;
            writing-mode: initial;
        }

        &__img {

            &-cnt {
                min-height: initial;
                max-height: initial;
                border-radius: 4px;
                height: 50vh;

                &:hover,
                & {
                    img {
                        // filter: grayscale(0.15) blur(3px);
                        filter: grayscale(0) blur(0px);
                        transform: scale(1);
                    }
                }

                &:hover .work-tiel__img-mask,
                & .work-tiel__img-mask {
                    transform: scale(1);

                    img {
                        opacity: 1;
                        filter: unset;
                        transform: scale(1);
                    }
                }

            }

            &-mask{
                display: none;
            }
        }
    }
}