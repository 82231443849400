// Import colors
@import '../componets/_base-style/colors.scss';


.footer {
    background-color: $color--white-20;

    a{
        text-decoration: none;
    }

    color: $color--black-30;

    &__cnt{
        position: relative;
        padding: 40px 0;
    }

    &__contact{
        border-bottom: 1px solid black;

        a{
            display: flex;
            align-items: center;

            i{
                margin-left: 40px;
                
                img{
                    transition: all .2s ease-in-out;
                    width: 120px;
                }
            }

            &:hover img{
                transform: translateX(18px);
            }
        }
    }

    &__links {
        padding: 40px 0 80px;
        display: flex;
        justify-content: space-between;


        ul,
        li {
            margin: initial;
            padding: initial;
            list-style: none;
            margin-bottom: 6px;

            .footer__spacer{
                margin-top: 12px;
            }

            &>ul{
                margin-top: 18px;
            }

            & a {
                text-decoration: none;
            }
        }

        ul {
            margin-right: 32px;
        }

        &:nth-of-type(1) {
            padding: 0;
            font-size: 14px;
        }
    }

    &__socials {
        text-align: right;

        ul {
            display: flex;
            justify-content: flex-end;
            margin: 0;

            li{
                margin: 0;
            }
            
            i {
                display: block;
                background-color: $color--black-30;
                width: 38px;
                height: 38px;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 16px;
                }
            }
        }

        p{
            font-size: 14px;
            margin: 4px 0 0;
        }

    }

    &__infos{
        font-size: 13px;
        p{
            margin: 4px 0 0;
        }
    }

    &__graphics{
        
        img{
            width: 600px;
            position: absolute;
            bottom: 0;
            right: 0;
            pointer-events: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer{

        margin-top: 64px;

        &__cnt{
            padding: 12px 0 40px;
        }

        &__links{
            flex-direction: column;
            padding: 40px 0;

            & > .footer__links{
                flex-direction: row;
            }
        }

        &__socials{
            margin-top: 32px;
            text-align: initial;

            ul{
                justify-content: initial;
            }
        }

        &__graphics img{
            display: none;
        }
    }
}