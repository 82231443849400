// Import colors
@import '../componets/_base-style/colors.scss';
// Import fonts
@import '../componets/_base-style/fonts.scss';



.frontpage {
    position: relative;

    &__gradient {
        height: 800px;

        &--left {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 150vh;
            background: linear-gradient(170deg, $color--blue 0%, rgba(56, 96, 255, 0) 90%);
            transition: .6s;
            opacity: 0.5;

            .theme--blue & {
                opacity: 1;
            }
        }

        &--right {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 150vh;
            background: linear-gradient(215deg, $color--red 10%, rgba(211, 78, 69, 0) 50%);
            transition: .6s;
            opacity: 0.5;

            .theme--blue & {
                opacity: 1;
            }
        }

        &--mixer-blue {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 150vh;
            background: linear-gradient(-3deg, rgba(105, 208, 217, 0) 0%, $color--blue-light 10%, rgba(105, 207, 217, 0) 54%);
            transition: .6s;
            opacity: 0;

            .theme--blue & {
                opacity: 1;
            }
        }

        &--mixer-gray {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 150vh;
            transition: .6s;
            background: linear-gradient(-3deg, rgba(54, 55, 55, 0) 0%, $color--black-20 10%, rgba(54, 55, 55, 0) 54%);
            opacity: 1;

            .theme--blue & {
                opacity: 0;
            }
        }
    }

    &__header {
        position: absolute;
        top: 320px;
        width: 100%;

        & h1 {
            max-width: 1600px;
            padding: 0 80px;
            margin: 0 auto;

            & span {
                max-width: 730px;
                display: inline-block;
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    .frontpage {

        &__header {
            position: relative;
            top: initial;
            padding: 22vh 20px 0;


            h1 {
                padding: initial;

                span>span {
                    display: inline-block;
                    margin-top: 16px;

                    font-family: 'Open Sans', sans-serif;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                }

            }
        }


        &__gradient {
            height: unset;
        }

        .projects{
            margin-top: 10vh;
        }
    }
}