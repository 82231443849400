// Import colors
@import './componets/_base-style/colors.scss';


.theme {
    position: relative;
    color: $color--white-20;
    background: $color--black-20;
    transition: .6s;

    // & ::selection {
    //     // background: $color--black-20;
    //     background: red;
    // }

    &--blue {
        background: $color--blue-light;

        // & ::selection {
        //     background: $color--red;
        // }
    }

    &.no-scroll {
        height: 100vh;
        overflow: hidden;
    }

}

.noise {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/img/noisy.png') 0 0 /150px auto;
    user-select: none;
    pointer-events: none;
    z-index: 1;

    &__anti {
        z-index: 2;
        position: relative;
    }
}

.wrapper {
    max-width: 1600px;
    padding: 0 80px;
    margin: 0 auto;
}

// Resets
a {
    color: inherit;
}


// text selected
.h-b-c {

    &-1 ::selection{ background-color: #96dde4; }
    &-2 ::selection{ background-color: #88cbe7; }
    &-3 ::selection{ background-color: #7ab9ea; }
    &-4 ::selection{ background-color: #6ca7ed; }
    &-5 ::selection{ background-color: #5e95f0; }
    &-6 ::selection{ background-color: #5083f3; }
    &-7 ::selection{ background-color: #426ff6; }
    &-8 ::selection{ background-color: #3860ff; }
    &-9 ::selection{ background-color: #4a56ff; }
    &-10 ::selection{ background-color: #5c4cff; }
    &-11 ::selection{ background-color: #6e42ff; }
    &-12 ::selection{ background-color: #8038ff; }
    &-13 ::selection{ background-color: #9230ff; }
    &-14 ::selection{ background-color: #a428ff; }
    &-15 ::selection{ background-color: #b620ff; }
    &-16 ::selection{ background-color: #a72aff; }
    &-17 ::selection{ background-color: #9835ff; }
    &-18 ::selection{ background-color: #7a4bff; }
    &-19 ::selection{ background-color: #5c61ff; }
    &-20 ::selection{ background-color: #3e77ff; }
    &-21 ::selection{ background-color: #2f82ff; }
    &-22 ::selection{ background-color: #5083f3; }
    &-23 ::selection{ background-color: #7ab9ea; }
    &-24 ::selection{ background-color: #88cbe7; }
    &-25 ::selection{ background-color: #96dde4; }


}

.projects {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 100px 80px;
    padding-bottom: 370px;
}


.page-transtion-enter {
    position: absolute;
    opacity: 0;
    filter: blur(5px);
    animation: page-transtion-collapse-content 1ms 510ms forwards;
}

.page-transtion-enter.page-transtion-enter-active {
    opacity: 1;
    filter: blur(0);
    transition: all 500ms 700ms ease-in-out;
}

.page-transtion-exit {
    opacity: 1;
    filter: blur(0);
}

.page-transtion-exit-active {
    opacity: 0;
    filter: blur(5px);
    transition: all 500ms ease-in-out;
}

@keyframes page-transtion-collapse-content {
    from {
        position: absolute;
    }

    to {
        position: relative;
    }
}

@media only screen and (max-width: 600px) {
    .wrapper {
        padding: 0 20px;
    }

    .projects {
        margin-top: 64px;
        grid-gap: 32px;
        padding-bottom: initial;
    }
}