// Import colors
@import './_base-style/colors.scss';

// #small-projects {
//     &>img:last-of-type {
//         transform: rotate(180deg);
//     }
// }

.small-projects {
    position: relative;
    padding: 110px 0;
    height: 115vh;
    background: $color--black-30;
    overflow: hidden;

    & h2 {
        margin: 0;
        // user-select: none;
        pointer-events: none;
    }

    & p {
        margin: 12px 0 0;
        font-size: 18px;
    }

    &__case {
        position: absolute;
        width: 200px;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
        transform: translate(-50%, 0%);
        animation: case-animation 3.5s .5s;
        user-select: none;
        pointer-events: none;
        z-index: 2;
    }

    &__breakout {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 180vh;
        width: 180vw;
        will-change: transform;
    }

    &__grid {
        width: 100%;
        height: 100%;
        padding: 80px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(12, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        &-item {

            transition: .02s;

            &--1 {
                // grid-area: 3 / 4 / 4 / 6;
                grid-area: 2 / 2 / 4 / 4;
            }

            &--2 {
                // grid-area: 1 / 8 / 3 / 11;
                grid-area: 4 / 6 / 8 / 8;
            }

            &--3 {
                // grid-area: 5 / 1 / 7 / 4;
                grid-area: 3 / 9 / 5 / 11;
            }

            &--4 {
                // grid-area: 6 / 6 / 8 / 9;
                grid-area: 7 / 2 / 10 / 4;
            }

            &--5 {
                // grid-area: 7 / 10 / 9 / 13;
                // grid-area: 10 / 6 / 12 / 8;
                grid-area: 10 / 5 / 12 / 7;
            }

            &--6 {
                // grid-area: 9 / 2 / 11 / 5;
                grid-area: 9 / 10 / 12 / 12;
            }

            &--7 {
                grid-area: 10 / 8 / 13 / 11;
            }

            img {
                // width: 100%;

            }
        }
    }

    @keyframes case-animation {
        0% {
            opacity: 1;
        }

        20% {
            transform: translate(-50%, 0%);
        }

        100% {
            transform: translate(-50%, -30%);
            opacity: 0;
        }
    }
}


@media only screen and (max-width: 600px) {

    .small-projects,
    #small-projects {
        display: none;
    }

}