// Import colors
@import './_base-style/colors.scss';


.nav {

    ul,
    li {
        margin: unset;
        padding: unset;
        list-style: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    opacity: 1;
    transition: .4s;
    pointer-events: none;

    &__hide {
        opacity: 0;
        user-select: none;
        pointer-events: none;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        max-width: 1600px;
        padding: 80px 80px 0;
        margin: 0 auto;

        & div {
            pointer-events: all;
        }
    }

    &__title {
        & a {
            text-decoration: none;
        }

        & h2 {
            font-size: 18px;
            line-height: 1.06;
            font-weight: 700;
            margin: unset;
            padding: unset;
        }

        &-opc {
            opacity: 0.6;
        }
    }

    &__menu-site-theme {
        margin-bottom: 48px;

        &__switch {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &-cnt {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;

            }

            & input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            // & input:checked ~ &-cnt .nav__menu-site-theme__slider:before {
            //     transform: translate(-17px, -50%);
            //     background: $color--black-20;
            // }

            & input:checked~&-cnt .nav__menu-site-theme__slider:before {
                transform: translate(-17px, -50%);
                background: $color--blue-light;
            }

            & input:checked~.nav__menu-site-theme__quote .nav__menu-site-theme__quote--blue {
                transform: translateY(-100%);
                opacity: 1;
            }

            & input:checked~.nav__menu-site-theme__quote .nav__menu-site-theme__quote--black {
                transform: translateY(100%);
                opacity: 0;
            }

        }

        &__slider {
            background: #F4EFE2;
            border-radius: 20px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            transition: .4s;

            &:before {
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                border-radius: 15px;
                right: 2px;
                top: 50%;
                transform: translate(0, -50%);
                background: $color--black-20;
                will-change: transform, background;
                transition: .4s;
            }
        }

        &__quote {
            font-size: 7px;
            line-height: 9px;
            height: 9px;
            overflow: hidden;
            margin: 3px 0 0;

            & span {
                display: block;
                text-align: right;
                will-change: transform, opacity;
                transition: .4s;
            }

            &--blue {
                transform: translateY(100%);
                opacity: 1;
            }

            &--black {
                transform: translateY(0);
                opacity: 1;
            }
        }

    }

    &__menu-desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &:hover a {
            filter: blur(2px);
        }

        & a {
            font-size: 21px;
            line-height: 1;
            font-weight: 700;
            text-decoration: none;
            transition: .4s;

            &:hover {
                filter: blur(0px);
            }

            &:nth-child(odd) {
                margin: 3px 0;
            }
        }
    }

    &__menu-mobile {
        display: none;

        &-btn {
            padding: 7px;
            border: none;
            background-color: rgba(255, 255, 255, 0.3);
            width: 38px;
            height: 38px;
            border-radius: 100%;

            &-lines {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;

                &::before {
                    content: "";
                    position: absolute;
                    top: 6px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 6px;
                }

                &-center,
                &::before,
                &::after {
                    display: inline-block;
                    height: 2px;
                    width: 100%;
                    border-radius: 3px;
                    background-color: #fff;
                }
            }

            &.active {

                .nav__menu-mobile-btn-lines::before,
                .nav__menu-mobile-btn-lines::after {
                    top: 11px;
                    transform: rotate(45deg);
                }

                .nav__menu-mobile-btn-lines-center {
                    transform: rotate(-45deg);
                }
            }

        }

        &-burger {
            display: none;

            &>* {
                z-index: 2;
            }
        }
    }

}

.theme {
    .nav__menu-mobile-burger {
        background-color: $color--black-10;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(150deg, $color--blue 0%, transparent 30%);
            opacity: 0.5;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(190deg, $color--red 0%, transparent 30%);
            opacity: 0.5;
        }
    }
}

.theme--blue {
    .nav__menu-mobile-burger {
        background-color: $color--blue-light2;

        &::before {
            // background-image: linear-gradient(150deg, $color--blue 0%, transparent 30%);
            opacity: 1;
        }

        &::after {
            // background-image: linear-gradient(190deg, $color--red 0%, transparent 30%);
            opacity: 1;
        }
    }

    .footer__socials ul i {
        background-color: $color--blue-light;
    }

}


@media only screen and (max-width: 600px) {

    .nav {

        &__inner {
            padding: 20px 20px 0;
        }

        &__menu-site-theme {
            display: none;
        }

        &__menu-desktop {
            display: none;
        }

        &__title h2 {
            font-size: 16px;
        }

        &__menu-mobile {
            display: inline-block;
            position: relative;
            z-index: 10;

            &-burger {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                backdrop-filter: blur(0px);
                padding: 67px 20px 120px;

                flex-direction: column;
                justify-content: space-between;

                transition-delay: 0.1s;
                transition: all 8s cubic-bezier(0.16, 1, 0.3, 1);

                ul li {
                    overflow: hidden;
                    margin-bottom: 12px;
                }

                a {
                    text-decoration: none;
                    font-size: 60px;
                    line-height: 1;
                    font-weight: 900;
                    letter-spacing: -4.5px;
                    // margin-bottom: 12px;
                    color: $color--white-20;
                }

                // text animation
                a:not(.footer__socials a) {
                    display: inline-block;
                    transform: translateY(100%);
                    opacity: 0;
                    // transition: all .8s cubic-bezier(.16, 1, .3, 1);
                    // transition-delay: 1s;
                }

                .footer__socials {
                    opacity: 0;

                    transition: all .8s cubic-bezier(.16, 1, .3, 1) a {
                        font-size: 38px;
                        letter-spacing: -1.5px;
                    }
                }

                &.active {
                    display: flex;
                    backdrop-filter: blur(2px);

                    a:not(.footer__socials a) {
                        animation: burger-text .8s .2s cubic-bezier(.16, 1, .3, 1) forwards;
                    }
                    
                    .footer__socials {
                        animation: opacity-ani 1.2s .5s ease-in-out forwards;

                        a {
                            font-size: 38px;
                            letter-spacing: -1.5px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes burger-text {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes opacity-ani {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}