.work-header{
    padding-top: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__details{
        margin-bottom: 42px;

        &-flex{
            display: flex;
            justify-content: space-between;

            & p{
                font-size: 18px;
                font-weight: 700;
                margin: 0 0 12px;
            }
        }
    }

    &__title{
        margin: 0;
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 10%;

        &-cnt{
            width: 100%;
            height: 80vh;
        }
    }

}

@media only screen and (max-width: 600px) {

    .work-header {
        padding-top: 20vh;

        &__details{
            margin-bottom: 22px;
        }

        &__img-cnt {
            width: 100%;
            height: 50vh;
        }
    }
}